import { cn } from "../magicui/lib/utils";
import Marquee from "../magicui/marquee";
import { Logos } from '../../data/Logos'

const companies  = [
  // <Logos.BitBuckeIcon />,
  // <Logos.ConfluenceIcon />,
  // <Logos.CreativeCloudIcon />,
  <Logos.TCSIcon />,
  //<Logos.TowerIcon />,
  <Logos.InfosysIcon />,
  <Logos.AccentureIcon />,
  <Logos.RelianceIcon />,
  <Logos.ZenkaIcon />
];

const ReviewCard = ({ company } : { company: any} ) => (
  <div
    className={cn(
      "relative w-24 cursor-pointer overflow-hidden  text-center scale-50",
    )}
  >
    <span className="scale-50">{company}</span>
  </div>
);

const CRTHeroSlide = () => (
  <div className="relative flex h-fit w-full flex-col items-center justify-center overflow-hidden rounded-lg bg-background py-3">
    <Marquee  className="[--duration:20s]">
      {companies.map((company, idx) => (
        <ReviewCard key={idx} company={company} />
      ))}
    </Marquee>
  </div>
);

export default CRTHeroSlide;
