import React from 'react'
import { IconCloudComponent } from './IconCloudComponenet'
import AnimatedShinyText from '../../magicui/animated-shiny-text'
import { ArrowRightIcon } from "@radix-ui/react-icons";
const InternshipHero = () => {
  return (
    <div className='flex justify-between h-[510px] mx-7 md:mx-16 lg:mx-24'>
      <div className='w-full md:w-1/2   '>

        <div className="z-10 flex h-fit items-start justify-start font-Inter">
          <div
            className="group rounded-md border border-black/5 bg-white text-base transition-all ease-in hover:cursor-pointer hover:bg-neutral-200 dark:border-white/5 dark:bg-neutral-900 dark:hover:bg-neutral-800">

            <AnimatedShinyText className="inline-flex items-center px-4 py-1 transition ease-out hover:text-neutral-600 hover:duration-300 hover:dark:text-neutral-400 text-[#344054] text-[11px] lg:text-sm">
              <div className='border rounded-md px-1 -ml-2'>
            <span className="w-2 h-2 bg-purple-500 rounded-full shadow-sm shadow-purple-500/50 inline-block mb-0.5"></span>
              <span className='text-[10px] md:text-base'> What&apos;s new? </span>

              </div>
              <span className='ml-1  font-normal'><a href="/kodefest">Register in coding buzz for free internship</a> </span>

              <ArrowRightIcon className="ml-1 size-3 transition-transform duration-300 ease-in-out group-hover:translate-x-0.5" />
            </AnimatedShinyText>
          </div>
        </div>
        <h1 className='text-[#101828]  text-4xl md:text-4xl lg:text-6xl text-center lg:text-start font-semibold mt-4'>Transform Learning into Action </h1>
        <p className='lg:w-96 mt-6 text-[#475467] font-normal text-center lg:text-start text-xl'>
          Work on meaningful projects with guidance from top professionals. Elevate your career prospects with our internships.
        </p>
        <div className='mt-12 flex flex-col lg:flex-row gap-3 mx-1 '>
          <a href="#benefits">
            <button className='border rounded-md px-3 py-2 md:px-4 lg:px-7 lg:py-4 bg-white text-[#344054] font-semibold'>What do you get?</button>
          </a>
          <a href="#Explore">
            <button className='border rounded-md px-3 py-2 md:px-4 lg:px-7 lg:py-4 bg-[#7F70ED] text-white font-medium'>Apply Now</button>
          </a>
        </div>
      </div>
      <div className='hidden lg:block  '>

      <IconCloudComponent />
      </div>

    </div>
  )
}

export default InternshipHero
