import React from 'react'

const CRTBanner = () => {
  return (
    <div className="relative">
      {/* Banner Section */}
      <div className="bg-[#53389E] w-full text-center py-8 md:py-16 lg:py-24"> {/* Adjusted padding */}
        <div className="max-w-7xl mx-auto text-white px-7 md:px-14 lg:px-20">
          <p className="text-lg">Info</p>
          <h1 className="lg:text-5xl font-semibold md:text-3xl text-2xl md:leading-[5rem] my-3">
            What is campus recruitment training (CRT)?
          </h1>
          <p className="pt-4 text-[#E9D7FE] text-base md:text-xl">
            Campus Recruitment Training (CRT) is a structured program designed to prepare students for employment by enhancing their skills in various areas. It typically includes training in aptitude, communication, resume writing, interview preparation, and job search strategies. CRT aims to bridge the gap between academic education and industry requirements, ensuring students are well-equipped to enter the job market successfully.
          </p>
        </div>
      </div>

      {/* Banner Image */}
      <img
        src="./CRTBanner.svg"
        alt="Campus Recruitment Training Banner"
        className="mx-auto md:w-[85%] md:h-[40rem] w-96 -mt-8 lg:-mt-16" 
        // Adjusted margin-top to reduce excessive spacing
      />
    </div>
  );
}

export default CRTBanner;
