import React from "react";
import Container  from "../../assets/emailTemplate/Container.svg";
import Content from "../../assets/emailTemplate/Content.svg";
import IconImg1 from "../../assets/emailTemplate/icon-img-26-1.svg";
import IconImg2 from "../../assets/emailTemplate/icon-img-26-2.svg";
import IconImg3 from "../../assets/emailTemplate/icon-img-26-3.svg";
import IconImg4 from "../../assets/emailTemplate/icon-img-26-4.svg";
import IconImg5 from "../../assets/emailTemplate/icon-img-26-5.svg";
import IconImg6 from "../../assets/emailTemplate/icon-img-26-6.svg";
import Facebook from "../../assets/emailTemplate/icons8-facebook.svg";
import Instagram from "../../assets/emailTemplate/icons8-instagram.svg";
import Twitter from "../../assets/emailTemplate/icons8-twitterx.svg";
import YouTube from "../../assets/emailTemplate/icons8-youtube.svg";
import Internship from "../../assets/emailTemplate/Internship.svg";
import TransparentHeader from "../../assets/emailTemplate/kodekamp_transparent_header.svg";
import Projects from "../../assets/emailTemplate/Projects.svg";
import TopFrame from "../../assets/emailTemplate/topFrame.svg";

const images = [
  { src: Container, name: "Container.svg" },
  { src: Content, name: "Content.svg" },
  { src: IconImg1, name: "icon-img-26-1.svg" },
  { src: IconImg2, name: "icon-img-26-2.svg" },
  { src: IconImg3, name: "icon-img-26-3.svg" },
  { src: IconImg4, name: "icon-img-26-4.svg" },
  { src: IconImg5, name: "icon-img-26-5.svg" },
  { src: IconImg6, name: "icon-img-26-6.svg" },
  { src: Facebook, name: "icons8-facebook.svg" },
  { src: Instagram, name: "icons8-instagram.svg" },
  { src: Twitter, name: "icons8-twitterx.svg" },
  { src: YouTube, name: "icons8-youtube.svg" },
  { src: Internship, name: "Internship.svg" },
  { src: TransparentHeader, name: "kodekamp_transparent_header.svg" },
  { src: Projects, name: "Projects.svg" },
  { src: TopFrame, name: "topFrame.svg" },
];

const ImageGallery = () => {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "1rem",
        justifyContent: "center",
        alignItems: "center",
        padding: "1rem",
        backgroundColor: "#f9f9f9",
      }}
    >
      {images.map((image, index) => (
        <div key={index} style={{ textAlign: "center", margin: "0.5rem" }}>
          <img
            src={image.src}
            alt={image.name}
            style={{ maxWidth: "100px", height: "auto", display: "block", margin: "0 auto" }}
          />
          <p style={{ fontSize: "0.8rem", color: "#333", marginTop: "0.5rem" }}>
            {image.name}
          </p>
        </div>
      ))}
    </div>
  );
};

export default ImageGallery;
