import React from 'react'
import SignupFormDemo from '../example/signup-form-demo'
import banner from './kodefestbanner.png'
import bird from './bird.png'
import content from './Content.png'
// import { AnimatedListDemo } from '../Bentocard/AnimatedListDemo'

const KodeFeeeest = () => {
  return (
    <div className='px-24'>
      <div className='flex flex-col md:flex-row'>

          <div>Register in coding buzz for free internship
            <SignupFormDemo/>
          </div>
          <div className='relative'>
            <img src={banner} alt="" className='h-[37rem]'/>
            <img src={bird} alt="" className='absolute -bottom-28 -right-20 -rotate-45' />
          </div>
      </div>


      {/* bottom */}
      <div className='flex flex-col md:flex-row items-center justify-center mb-5 py-3'>
        <div className='max-w-xl '>
          <h1 className='text-headingBlack font-semibold text-4xl'>
          We Are available on any platform - you can grab us on the GOOO!
          </h1>
          <p className='text-paraGray font-normal text-xl py-4'>
            Start your trail today....
          </p>
          <div className='flex gap-4'>
            <button className='bg-neutral-950 text-white px-3 py-2 rounded-lg hover:scale-105 hover:shadow-md transition-all ease-in-out'>
              Explore CRT!
            </button>
            <button className='border-neutral-950 border px-3 py-2 rounded-lg hover:scale-105 hover:shadow-md transition-all ease-in-out'>
              Explore Projects
            </button>
          </div>
        </div>

        <div className='relative'>
          <img src={content} alt="" />
          {/* <AnimatedListDemo className=' border-none shadow-none absolute bottom-3 -left-10 h-[300px] '/> */}
        </div>
      </div>

      </div>
  )
}

export default KodeFeeeest
