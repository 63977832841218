import React, { useState } from "react";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { cn } from "../magicui/lib/utils";
import { Checkbox } from "../ui/checkbox";
import { registerKodefest } from "../../services/mail.service";

// Define types for form data, errors, and toast messages
type FormData = {
  name: string;
  email: string;
  university: string;
  branch: string;
  phone: string;
  terms: boolean;
};

type Errors = {
  name: string;
  email: string;
  phone: string;
  terms: string;
};

type ToastMessage = {
  visible: boolean;
  message: string;
  success: boolean;
};

export default function SignupFormDemo() {
  const [formData, setFormData] = useState<FormData>({
    name: "",
    email: "",
    university: "",
    branch: "",
    phone: "",
    terms: false,
  });

  const [errors, setErrors] = useState<Errors>({
    name: "",
    email: "",
    phone: "",
    terms: "",
  });

  const [showToast, setShowToast] = useState<ToastMessage>({
    visible: false,
    message: "",
    success: true,
  });

  const validateForm = () => {
    const newErrors: Errors = {
      name: "",
      email: "",
      phone: "",
      terms: "",
    };
    let isValid = true;

    if (!formData.name) {
      newErrors.name = "Name is required";
      isValid = false;
    }

    if (!formData.email) {
      newErrors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email address is invalid";
      isValid = false;
    }

    if (!formData.phone || !/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Enter a valid 10-digit phone number";
      isValid = false;
    }

    if (!formData.terms) {
      newErrors.terms = "You must agree to the terms and conditions";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value, type, checked } = e.target as HTMLInputElement;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  
    // Clear the error for the field that's being edited
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };
  
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        await registerKodefest(formData);
        console.log("Form submitted successfully with data:", formData);
        setShowToast({
          visible: true,
          message: "Registered successfully! We will notify you soon!",
          success: true,
        });
        setTimeout(() => setShowToast({ visible: false, message: "", success: true }), 3000);
        setFormData({
          name: "",
          email: "",
          university: "",
          branch: "",
          phone: "",
          terms: false,
        });
      } catch (error) {
        setShowToast({
          visible: true,
          message: "Failed to register. Please try again.",
          success: false,
        });
        setTimeout(() => setShowToast({ visible: false, message: "", success: true }), 3000);
        console.error("Error submitting form:", error);
      }
    }
  };

  return (
    <div className="max-w-xl w-full rounded-none md:rounded-2xl p-4 md:p-8 shadow-input bg-white dark:bg-black">
      <h2 className="font-bold text-5xl text-headingBlack dark:text-neutral-200">
        KodeFeeeest!
      </h2>
      <p className="text-paraGray text-xl max-w-xl mt-1 dark:text-neutral-300">
        Registrations for kodefest not started yet. Meanwhile, subscribe, and we will notify you!
      </p>

      <form className="my-4" onSubmit={handleSubmit}>
        <LabelInputContainer className="mb-4">
          <Label htmlFor="name">Name</Label>
          <Input id="name" placeholder="name" type="text" name="name" value={formData.name} onChange={handleChange} />
          {errors.name && <span className="text-red-500">{errors.name}</span>}
        </LabelInputContainer>
        <LabelInputContainer className="mb-4">
          <Label htmlFor="email">Email Address</Label>
          <Input id="email" placeholder="projectmayhem@fc.com" type="email" name="email" value={formData.email} onChange={handleChange} />
          {errors.email && <span className="text-red-500">{errors.email}</span>}
        </LabelInputContainer>
        <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 mb-4">
          <LabelInputContainer>
            <Label htmlFor="university">University/College</Label>
            <Input id="university" placeholder="MIT" type="text" name="university" value={formData.university} onChange={handleChange} />
          </LabelInputContainer>
          <LabelInputContainer>
            <Label htmlFor="branch">Branch</Label>
            <Input id="branch" placeholder="CSE" type="text" name="branch" value={formData.branch} onChange={handleChange} />
          </LabelInputContainer>
        </div>
        <LabelInputContainer className="mb-4">
          <Label htmlFor="phone">Phone Number</Label>
          <Input id="phone" placeholder="+919087654321" type="text" name="phone" value={formData.phone} onChange={handleChange} />
          {errors.phone && <span className="text-red-500">{errors.phone}</span>}
        </LabelInputContainer>
        <div className="flex items-center space-x-2 mb-4">
        <Checkbox
              id="terms"
              name="terms"
              checked={formData.terms}
              onCheckedChange={(checked: boolean) =>
                handleChange({
                  target: { name: "terms", type: "checkbox", checked } as HTMLInputElement,
                } as React.ChangeEvent<HTMLInputElement>)
              }
          />
          <Label htmlFor="terms">Accept terms and conditions</Label>
          {errors.terms && <span className="text-red-500">{errors.terms}</span>}
        </div>

        <button
          className="bg-themepruple text-white w-full rounded-md h-10 font-medium"
          type="submit"
        >
          Register Now!
          <BottomGradient />
        </button>
      </form>
      {showToast.visible && (
        <div className={`p-4 text-white rounded-md ${showToast.success ? "bg-green-500" : "bg-red-500"}`}>
          {showToast.message}
        </div>
      )}
    </div>
  );
}

const BottomGradient = () => {
  return (
    <>
      <span className="block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
      <span className="block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
    </>
  );
};

const LabelInputContainer = ({ children, className }: { children: React.ReactNode; className?: string }) => {
  return <div className={cn("flex flex-col space-y-2 w-full", className)}>{children}</div>;
};
