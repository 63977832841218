import React from 'react'
import CRTIntro from './CRTIntro'
import CRTPricing from './CRTPricing'
import CRTPerks from './CRTPerks'
import CRTAddons from './CRTAddons'
import CRTPlaced from './CRTPlaced'
import CRTDemoForm from './CRTDemoForm'
import CRTHero from './CRTHero'
import FAQ from '../FAQ'
import { FAQ_data } from '../../data/FAQ'

const CRTPage = () => {
  return (
    <div className=''>
      <CRTHero/> 
      <CRTIntro/>
      <div id='pricing'>
      <CRTPricing/>
      </div>
      <CRTPerks/>
      <CRTAddons/>
      <CRTPlaced/>
      <CRTDemoForm/>
      <FAQ data={FAQ_data}/>
    </div>
  )
}

export default CRTPage