import { cn } from "../magicui/lib/utils";
import React from "react";
import { BentoGrid, BentoGridItem } from "../ui/bento-grid";
import {
  IconBoxAlignRightFilled,
  IconClipboardCopy,
  IconFileBroken,
  IconSignature,
  IconTableColumn,
} from "@tabler/icons-react";
import { motion } from "framer-motion";


export function BentoGridComponent() {
  return (
    <BentoGrid className="max-w-[74rem] mx-7 md:mx-auto md:auto-rows-[20rem] my-4 ">
      {items.map((item, i) => (
        <BentoGridItem
          key={i}
          title={item.title}
          description={item.description}
          header={item.header}
          className={cn("[&>p:text-lg] border border-neutral-300  ", item.className)}
          icon={item.icon}
        />
      ))}
    </BentoGrid>
  );
}

const SkeletonOne = () => {
  // const variants = {
  //   initial: {
  //     x: 0,
  //   },
  //   animate: {
  //     x: 10,
  //     rotate: 5,
  //     transition: {
  //       duration: 0.2,
  //     },
  //   },
  // };
  // const variantsSecond = {
  //   initial: {
  //     x: 0,
  //   },
  //   animate: {
  //     x: -10,
  //     rotate: -5,
  //     transition: {
  //       duration: 0.2,
  //     },
  //   },
  // };

  return (
    // <motion.div
    //   initial="initial"
    //   whileHover="animate"
    //   className="flex flex-1 w-full h-full min-h-[6rem] dark:bg-dot-white/[0.2] bg-dot-black/[0.2] flex-col space-y-2 "
    // >
    //   <motion.div
    //     variants={variants}
    //     className="flex flex-row rounded-full border border-neutral-100 dark:border-white/[0.2] p-2  items-center space-x-2 bg-white dark:bg-black"
    //   >
    //     <div className="h-6 w-6 rounded-full bg-gradient-to-r from-pink-500 to-violet-500 flex-shrink-0" />
    //     <div className="w-full bg-gray-100 h-4 rounded-full dark:bg-neutral-900" />
    //   </motion.div>
    //   <motion.div
    //     variants={variantsSecond}
    //     className="flex flex-row rounded-full border border-neutral-100 dark:border-white/[0.2] p-2 items-center space-x-2 w-3/4 ml-auto bg-white dark:bg-black"
    //   >
    //     <div className="w-full bg-gray-100 h-4 rounded-full dark:bg-neutral-900" />
    //     <div className="h-6 w-6 rounded-full bg-gradient-to-r from-pink-500 to-violet-500 flex-shrink-0" />
    //   </motion.div>
    //   <motion.div
    //     variants={variants}
    //     className="flex flex-row rounded-full border border-neutral-100 dark:border-white/[0.2] p-2 items-center space-x-2 bg-white dark:bg-black"
    //   >
    //     <div className="h-6 w-6 rounded-full bg-gradient-to-r from-pink-500 to-violet-500 flex-shrink-0" />
    //     <div className="w-full bg-gray-100 h-4 rounded-full dark:bg-neutral-900" />
    //   </motion.div>
    // </motion.div>
    <div>
      <img src="./progress.png" alt=""  className=" object-contain"/>
    </div>
  );
};
// const SkeletonTwo = () => {
//   const variants = {
//     initial: {
//       width: 0,
//     },
//     animate: {
//       width: "100%",
//       transition: {
//         duration: 0.2,
//       },
//     },
//     hover: {
//       width: ["0%", "100%"],
//       transition: {
//         duration: 2,
//       },
//     },
//   };
//   const arr = new Array(6).fill(0);
//   return (
//     <motion.div
//       initial="initial"
//       animate="animate"
//       whileHover="hover"
//       className="flex flex-1 w-full h-full min-h-[6rem] dark:bg-dot-white/[0.2] bg-dot-black/[0.2] flex-col space-y-2"
//     >
//       {arr.map((_, i) => (
//         <motion.div
//           key={"skelenton-two" + i}
//           variants={variants}
//           style={{
//             maxWidth: Math.random() * (100 - 40) + 40 + "%",
//           }}
//           className="flex flex-row rounded-full border border-neutral-100 dark:border-white/[0.2] p-2  items-center space-x-2 bg-neutral-100 dark:bg-black w-full h-4"
//         ></motion.div>
//       ))}
//     </motion.div>
//   );
// };
const SkeletonTwo = () => {
  return (
    <motion.div
     
      className="flex flex-1 w-full h-full min-h-[6rem] dark:bg-dot-white/[0.2] rounded-lg bg-dot-black/[0.2] flex-col space-y-2"
      style={{
        backgroundImage: "url('./bentoGrid/pricing.svg')",
        backgroundSize: "cover", 
        backgroundPosition: "center",
      }}
    >
      <motion.div className="h-full w-full rounded-lg"></motion.div>
    </motion.div>
  );
};

const SkeletonThree = () => {
  return (
    <motion.div
     
      className="flex flex-1 w-full h-full min-h-[6rem] dark:bg-dot-white/[0.2] rounded-lg bg-dot-black/[0.2] flex-col space-y-2"
      style={{
        backgroundImage: "url('./bentoGrid/OnCampus.svg')",
        backgroundSize: "cover", 
        backgroundPosition: "center",
      }}
    >
      <motion.div className="h-full w-full rounded-lg"></motion.div>
    </motion.div>
  );
};
const SkeletonFour = () => {
  const first = {
    initial: {
      x: 20,
      rotate: -5,
    },
    hover: {
      x: 0,
      rotate: 0,
    },
  };
  const second = {
    initial: {
      x: -20,
      rotate: 5,
    },
    hover: {
      x: 0,
      rotate: 0,
    },
  };
  return (
    <motion.div
      initial="initial"
      animate="animate"
      whileHover="hover"
      className="flex flex-1 w-full h-full min-h-[6rem] dark:bg-dot-white/[0.2] bg-dot-black/[0.2] flex-row space-x-2"
    >
      <motion.div
        variants={first}
        className="h-full w-1/3 rounded-2xl bg-white p-4 dark:bg-black dark:border-white/[0.1] border border-neutral-200 flex flex-col items-center justify-center"
      >
        <img
          src="https://res.cloudinary.com/dw2h36vj0/image/upload/v1730015482/woman_tnnysi.svg"
          alt="avatar"
          height="100"
          width="100"
          className="rounded-full h-10 w-10"
        />
        <p className="sm:text-sm text-xs text-center font-semibold text-neutral-500 mt-4">
          Durga - Faculty in JNTU
        </p>
        <p className="border border-red-500 bg-red-100 dark:bg-red-900/20 text-red-600 text-xs rounded-full px-2 py-0.5 mt-4">
          Developer
        </p>
      </motion.div>
      <motion.div className="h-full relative z-20 w-1/3 rounded-2xl bg-white p-4 dark:bg-black dark:border-white/[0.1] border border-neutral-200 flex flex-col items-center justify-center">
        <img
          src="https://res.cloudinary.com/dw2h36vj0/image/upload/v1730015573/man_v3higr.svg"
          alt="avatar"
          height="100"
          width="100"
          className="rounded-full h-10 w-10 bg-slate-400"
        />
        <p className="sm:text-sm text-xs text-center font-semibold text-neutral-500 mt-4">
          Srinivas - 15 years of experience
        </p>
        <p className="border border-green-500 bg-green-100 dark:bg-green-900/20 text-green-600 text-xs rounded-full px-2 py-0.5 mt-4">
          Python Trainer
        </p>
      </motion.div>
      <motion.div
        variants={second}
        className="h-full w-1/3 rounded-2xl bg-white p-4 dark:bg-black dark:border-white/[0.1] border border-neutral-200 flex flex-col items-center justify-center"
      >
        <img
          src="https://res.cloudinary.com/dw2h36vj0/image/upload/v1730015573/man_v3higr.svg"
          alt="avatar"
          height="120"
          width="120"
          className="rounded-full h-10 w-10 bg-orange-300"
        />
        <p className="sm:text-sm text-xs text-center font-semibold text-neutral-500 mt-4">
          Revanth - Faculty in IITD
        </p>
        <p className="border border-orange-500 bg-orange-100 dark:bg-orange-900/20 text-orange-600 text-xs rounded-full px-2 py-0.5 mt-4">
          Master in aptitude
        </p>
      </motion.div>
    </motion.div>
  );
};
const SkeletonFive = () => {

  return (
    // <motion.div
    //   initial="initial"
    //   whileHover="animate"
    //   className="flex flex-1 w-full h-full min-h-[6rem] dark:bg-dot-white/[0.2] bg-dot-black/[0.2] flex-col space-y-2"
    // >
    //   <motion.div
    //     variants={variants}
    //     className="flex flex-row rounded-2xl border border-neutral-100 dark:border-white/[0.2] p-2  items-start space-x-2 bg-white dark:bg-black"
    //   >
    //     <img
    //       src="https://pbs.twimg.com/profile_images/1417752099488636931/cs2R59eW_400x400.jpg"
    //       alt="avatar"
    //       height="100"
    //       width="100"
    //       className="rounded-full h-10 w-10"
    //     />
    //     <p className="text-xs text-neutral-500">
    //       There are a lot of cool framerworks out there like React, Angular,
    //       Vue, Svelte that can make your life ....
    //     </p>
    //   </motion.div>
    //   <motion.div
    //     variants={variantsSecond}
    //     className="flex flex-row rounded-full border border-neutral-100 dark:border-white/[0.2] p-2 items-center justify-end space-x-2 w-3/4 ml-auto bg-white dark:bg-black"
    //   >
    //     <p className="text-xs text-neutral-500">Use PHP.</p>
    //     <div className="h-6 w-6 rounded-full bg-gradient-to-r from-pink-500 to-violet-500 flex-shrink-0" />
    //   </motion.div>
    // </motion.div>
    <div className="w-full">
      <img src="./resume.png" alt=""  className=" object-center"/>
      {/* <div className="bg-red-500 h-44 w-80"> */}

      {/* </div> */}
    </div>

  );
};
const items = [
  {
    title: "Real time progress Monitoring",
    description: (
      <span className="text-sm">
        Track you progress in real time with KAMPKODE's monitoring tools.
      </span>
    ),
    header: <SkeletonOne />,
    className: "md:col-span-1",
    icon: <IconClipboardCopy className="h-4 w-4 text-neutral-500" />,
  },
  {
    title: "Affordable Prices.",
    description: (
      <span className="text-sm">
        Gain inside knowledge and practical insights.
      </span>
    ),
    header: <SkeletonTwo />,
    className: "md:col-span-1",
    icon: <IconFileBroken className="h-4 w-4 text-neutral-500" />,
  },
  {
    title: "On-Campus learning experience.",
    description: (
      <span className="text-sm">
        Tailored curriculum delivered directly to your institution.
      </span>
    ),
    header: <SkeletonThree />,
    className: "md:col-span-1",
    icon: <IconSignature className="h-4 w-4 text-neutral-500" />,
  },
  {
    title: "Learn from industry Leaders.",
    description: (
      <span className="text-sm">
        Gain inside knowledge and practical insights.
      </span>
    ),
    header: <SkeletonFour />,
    className: "md:col-span-2",
    icon: <IconTableColumn className="h-4 w-4 text-neutral-500" />,
  },

  {
    title: "LateX powered Resume & RoadMap ",
    description: (
      <span className="text-sm">
        Summarize your lengthy documents with AI technology.
      </span>
    ),
    header: <SkeletonFive />,
    className: "md:col-span-1",
    icon: <IconBoxAlignRightFilled className="h-4 w-4 text-neutral-500" />,
  },
];
