import { Logos } from '../../data/Logos';


interface FeatureItemProps {
  Icon: React.ElementType;
  title: string;
  description: string;
}

interface PricingCardProps {
  Icon: React.ElementType;
  title: string;
  features: string[];
  buttonText: string;
}


const FeatureItem: React.FC<FeatureItemProps> = ({ Icon, title, description }) => (
  <div className='flex gap-3 my-6'>
    <div><Icon /></div>
    <div>
      <h1 className='text-headingBlack font-semibold text-xl '>{title}</h1>
      <p className='text-base font-normal text-paraGray my-2'>{description}</p>
    </div>
  </div>
);


const PricingCard: React.FC<PricingCardProps> = ({ Icon, title, features, buttonText }) => (
  <div className='bg-[#f9f5ff] px-7 py-5 rounded-md text-center shadow-lg flex flex-col justify-evenly items-start w-[22rem] mx-auto'>
    <div className='flex flex-col justify-center items-center gap-3 min-h-40'>
      <Icon />
      <h1 className='text-themepruple font-semibold text-xl'>{title}</h1>
    </div>
    <div className='flex flex-col gap-5 py-3 md:max-h-64  '>
      {features.map((feature, index) => (
        <span key={index} className='flex gap-2'>
          <Logos.CheckIcon />
          <span className='text-themepruple font-normal text-base text-start'>{feature}</span>
        </span>
      ))}
    </div>
    
        <a href="./CRT_Curriculum_Documentation.pdf" className='w-full'>
      <button className='text-white bg-themepruple w-full py-2 rounded-md mt-5'>
        {buttonText}
        </button>
        </a>
    
  </div>
);
const featuresList: FeatureItemProps[] = [
    {
      Icon: Logos.ChatBubleIcon,
      title: 'Programming Language Proficiency',
      description: 'Whether you have a team of 2 or 200, our shared team inboxes keep everyone on the same page and in the loop.',
    },
    {
      Icon: Logos.ThunderBoltIcon,
      title: '100+ Hours of Live Training',
      description: 'An all-in-one customer service platform that helps you balance everything your customers need to be happy.',
    },
    {
      Icon: Logos.ChartBreakoutSquare,
      title: 'In-College Training Delivery',
      description: 'Measure what matters with Untitled’s easy-to-use reports. You can filter, export, and drill down on the data in a couple clicks.',
    },
  ];

  const pricingPlans: PricingCardProps[] = [
    {
      Icon: Logos.PieChatLogo,
      title: 'Aptitude & Reasoning Training',
      features: [
        'Quantitative aptitude ',
        'Logical/Non- Verbal Reasoning',
        'Data Interpretation',
        'Verbal Ability',
        'and many more...',
      ],
      buttonText: 'Get Syllabus',
    },
    {
      Icon: Logos.SmileLogo,
      title: 'Advanced Communication & Verbal Skills',
      features: [
        'Effective Presentation Skills',
        'Comprehension Strategies',
        'Advanced Grammar Mastery',
        'Precision in Technical Writing',
        'and many more...'
      ],
      buttonText: 'Get Syllabus',
    },
  ];

const CRTPricing: React.FC = () => {

 

  return (
    <div className='lg:px-20 md:px-14 px-7 bg-white pt-3'>
      <div className='px-1'>
        <span className='text-[#6941C6] text-base font-semibold'>KampKode offers - to upgrade your students</span>
        <h2 className='text-headingBlack font-semibold text-4xl my-3'>Unlock Opportunities with Proven Training</h2>
        <p className='text-paraGray text-lg'>Preparing students for real-world challenges with hands-on learning.</p>
      </div>
      <div className='grid grid-cols-1 xl:grid-cols-3 py-6 md:py-12  px-5 gap-4'>
        <div className='w-96 md:max-w-md   mx-auto xl:col-span-1'>
          {featuresList.map((feature, index) => (
            <FeatureItem key={index} {...feature} />
          ))}
        </div>
        <div className='grid xl:col-span-2'>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-y-4'>
        {pricingPlans.map((plan, index) => (
          <PricingCard key={index} {...plan} />
        ))}
        </div>
        </div>
      </div>
    </div>
  );
};

export default CRTPricing;
