import React, { useState } from 'react';
import axios from 'axios';
import { kodefestStudent } from '../../types/types';

const baseUrl = process.env.REACT_APP_ENV
  ? "https://asia-south1-kampkode-ecb6c.cloudfunctions.net/kampkode/"
  : "http://127.0.0.1:5001/kodekamp-dev-7f75c/asia-south1/kampkode/";

const pageSize = 20;

const KodefestAdminPage: React.FC = () => {
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [students, setStudents] = useState<kodefestStudent[]>([]);
  const [totalStudents, setTotalStudents] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const fetchStudents = async (page = 0) => {
    try {
      const response = await axios.post(`${baseUrl}kodefest/registrations`, {
        startDate,
        endDate,
        page,
        pageSize,
      });
      setStudents(response.data.students);
      setTotalStudents(response.data.total);
      setCurrentPage(page);
    } catch (error) {
      console.error("Error fetching students:", error);
    }
  };

  const handlePageChange = (newPage: number) => {
    if (newPage >= 0 && newPage < Math.ceil(totalStudents / pageSize)) {
      fetchStudents(newPage);
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6">
      <h1 className="text-2xl font-bold mb-4 text-center"> Kodefest! Registered Students</h1>
      <div className="flex flex-col md:flex-row gap-4 mb-4">
        <div>
          <label className="block text-gray-700">Start Date:</label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="border border-gray-300 p-2 rounded w-full"
          />
        </div>
        <div>
          <label className="block text-gray-700">End Date:</label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="border border-gray-300 p-2 rounded w-full"
          />
        </div>
      </div>
      <button
        onClick={() => fetchStudents(0)}
        className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-200 mb-4"
      >
        Fetch Students
      </button>

      <p className="mb-4 font-semibold">Total Registered Students: {totalStudents}</p>

      {students.length > 0 && (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
            <thead>
              <tr className="bg-gray-100 border-b">
                <th className="px-4 py-2 text-left">Email</th>
                <th className="px-4 py-2 text-left">Name</th>
                <th className="px-4 py-2 text-left">University</th>
                <th className="px-4 py-2 text-left">Branch</th>
                <th className="px-4 py-2 text-left">Phone</th>
                <th className="px-4 py-2 text-left">Timestamp</th>
              </tr>
            </thead>
            <tbody>
              {students.map((student, index) => (
                <tr key={index} className="border-b">
                  <td className="px-4 py-2">{student.email}</td>
                  <td className="px-4 py-2">{student.name}</td>
                  <td className="px-4 py-2">{student.university}</td>
                  <td className="px-4 py-2">{student.branch}</td>
                  <td className="px-4 py-2">{student.phone}</td>
                  <td className="px-4 py-2">{new Date(student.timestamp).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Pagination Controls */}
      {students.length > 0 && (
        <div className="flex justify-center items-center mt-4">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 0}
            className="px-3 py-2 mr-2 bg-gray-300 rounded disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Previous
          </button>
          <span className="font-semibold mx-2">
            Page {currentPage + 1} of {currentPage === 0 ? 1: Math.ceil(totalStudents / pageSize)}
          </span>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={(currentPage + 1) * pageSize >= totalStudents}
            className="px-3 py-2 ml-2 bg-gray-300 rounded disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default KodefestAdminPage;
