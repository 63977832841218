import React from 'react';

const CRTIntro = () => {
  return (
    <div className="bg-white py-8 md:py-12">
      <div className="w-full md:w-2/4 mx-auto px-10">
        <h1 className="text-3xl font-semibold border-t py-5">Introduction</h1>
        <p className="pb-3">
          We offer a comprehensive program with 100+ hours of interactive training, hands-on projects, resume crafting, LinkedIn optimization, and exclusive job alerts. Our dedicated instructors provide personalized guidance to help students transition smoothly into the professional world. Join us to boost your employability and secure internships and job opportunities!
        </p>
        <div className="h-48">
            <p>
            Our offerings align with the needs of
engineering colleges across India,
blending expert mentorship, cuttingedge tools, and real-world experiences.
Students benefit from a holistic
approach to learning, combining theory
with hands-on application.
            </p>   
        </div> {/* Placeholder */}
      </div>
    </div>
  );
};

export default CRTIntro;
