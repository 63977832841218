const baseUrl = process.env.REACT_APP_ENV
  ? "https://asia-south1-kampkode-ecb6c.cloudfunctions.net/kampkode/"
  : "http://127.0.0.1:5001/kodekamp-dev-7f75c/asia-south1/kampkode/";
 
//Contact-us Post request
export const sendMessage = async (payload: {
  name: string;
  email: string;
  phone: string;
  message: string;
}) => {
  return await fetch(`${baseUrl}sendQuery`, {
    headers: {
      "Content-Type": "application/json",
      "X-Firebase-AppCheck": `appCheckTokenResponse.token`,
    },
    method: "POST",
    body: JSON.stringify(payload),
  }).then((resp) => resp.json());
};


//Email subscription for daily updates
export const subscribeEmail = async (payload: {
  email: string;
}) =>{
  return await fetch(`${baseUrl}api/subscribe`, {
    headers: {
      "Content-Type": "application/json",
      "X-Firebase-AppCheck": `appCheckTokenResponse.token`,
    },
    method: "POST",
    body: JSON.stringify(payload),
  }).then((resp) => resp.json());
};


//Kodefest registration POST request
export const registerKodefest = async (payload: {
  name: string;
  email: string;
  university: string,
  branch: string;
  phone: string;
}) => {
  return await fetch(`${baseUrl}kodefest`, {
    headers: {
      "Content-Type": "application/json",
      "X-Firebase-AppCheck": `appCheckTokenResponse.token`,
    },
    method: "POST",
    body: JSON.stringify(payload),
  }).then((resp) => resp.json());
};

export const getKodefestRegistrations = async(payload: {
  startDate: string,
  endDate: string,
  page: string,
  pageSize: string
}) => {
    return await fetch(`${baseUrl}kodefest/registrations`, {
      headers: {
        "Content-Type": "application/json",
        "X-Firebase-AppCheck": `appCheckTokenResponse.token`,
      },
      method: "POST",
      body: JSON.stringify(payload),
    }).then((resp) => resp.json()); 
}