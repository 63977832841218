import React, { Fragment, useEffect, useState } from "react";
import "./Navbar.css";
import kodekamp from "../assets/kodekamp_transparent_header.png";
import { Link, NavLink } from "react-router-dom";
import { auth, provider } from "../init-firebase";
import { signInWithPopup } from "firebase/auth";
import { CurrentUser } from "../types/types";
import UserMenu from "./UserModal/UserMenu";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../actions/actions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

// ArrowIcon Component
const ArrowIcon = ({ direction }: { direction: 'left' | 'right' }) => (
  <svg width="18" height="48" viewBox="0 0 18 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d={
        direction === 'left'
          ? "M10.525 30.648L2.813 23.288L10.525 15.96H14.205L6.461 23.288L14.205 30.648H10.525Z"
          : "M3.383 15.96H7.063L14.807 23.288L7.063 30.648H3.383L11.127 23.288L3.383 15.96Z"
      }
      fill="#7D6EEB"
    />
  </svg>
);

const Navbar = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.currentUserReducer);
  const [isScrolled, setIsScrolled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  useEffect(() => {
    const handleScroll = () => setIsScrolled(window.scrollY > 0);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const logIn = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user as unknown as CurrentUser;
        dispatch(setUser(user, dispatch));
      })
      .catch((error) => console.log(error.message));
  };

  const renderNavLink = (path: string, label: string) => (
    <NavLink to={path} onClick={() => setIsMobileMenuOpen(false)}>
      {({ isActive }) => (
        <li className="underline-animation">
          <div className="flex items-center ">
          {isActive && <div className="hidden md:block"> <ArrowIcon direction="left" /> </div> }
          <span className="text-[#1E003D] px-1">{label}</span>
          {isActive && <div className="hidden md:block"> <ArrowIcon direction="right" /> </div>}
          </div>
        </li>
      )}
    </NavLink>
  );

  return (
    <div
      className={`w-full h-[80px] flex-wrap text-white sticky top-0 navbar flex items-center justify-between z-50 md:px-10  ${
        isScrolled ? "scrolled" : ""
      }`}
    >
      <Link to="/">
        <img
          src={kodekamp}
          alt="logo"
          className="w-[200px] absolute top-5 left-6 md:left-[70px]"
          style={{pointerEvents: 'auto'}}
        />
      </Link>

      <button onClick={toggleMobileMenu} className="text-black focus:outline-none lg:hidden z-40  mr-10 mt-3 md:mt-0 absolute right-1">
        <FontAwesomeIcon icon={isMobileMenuOpen ? faTimes : faBars} size="lg" />
      </button>

      <div
        className={`text-[#6941C6] flex md:gap-8 md:items-center md:mx-10 text-nowrap cursor-pointer ${
          isMobileMenuOpen
            ? "flex-col gap-4 pt-20 backdrop-blur-lg w-screen mx-0 text-neutral-950 tracking-wider font-Inter px-5 shadow-lg min-h-screen font-semibold"
            : "hidden "
        } lg:flex md:mt-0`}
      >
        <Link to="/">
        <img
          src={kodekamp}
          alt="logo"
          className="w-[200px] absolute top-5 left-6 md:left-[70px] md:hidden"
          style={{pointerEvents: 'auto'}}
        />
      </Link>

        {renderNavLink("/crt", "CRT")}
        {renderNavLink("/internships", "Internships")}
        {renderNavLink("/projects", "Projects")}
        {renderNavLink("/about-us", "About us")}
        {renderNavLink("/contact-us", "Contact us")}
        

        {user && user.accessToken ? (
          <>
            <div className="h-10 w-10 ml-2 sm:ml-8 rounded-full overflow-hidden">
              <img
                src={user.photoURL}
                alt="avatar"
                className="h-full w-full object-cover cursor-pointer"
                onClick={() => setShowModal(true)}
                style={{pointerEvents: 'auto'}}
              />
            </div>
            {showModal && <UserMenu closeModal={() => setShowModal(false)} />}
          </>
        ) : (
          <li className="flex flex-col gap-2 w-full" style={{ listStyleType: 'none' }}>

            <button className="bg-[#6941C6] text-white py-1.5 px-2.5 rounded hover:bg-blue-500 transition duration-300 w-full my-2"
            onClick={logIn}>
              SignUp
            </button> 
            <button 
            className="bg-[#6941C6] text-white py-1.5 px-2.5 rounded hover:bg-blue-500 transition duration-300 w-full md:hidden"
            onClick={logIn}
            >
              LogIn
            </button>
          </li>
        )}
      </div>
    </div>
  );
};

export default Navbar;