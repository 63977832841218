import AnimatedShinyText from '../../magicui/animated-shiny-text';
import HyperText from '../../magicui/hyper-text';
import React from 'react';

interface ProjectHeroProps {
  title : string;
  description : string;
  category : string;
  image: string;
}

const ProjectHero = ({title,description,category,image}:ProjectHeroProps) => {
  //const shortTitle = title.split(" ")[0];
  return (
    <div className='flex flex-col md:flex-row pt-12 pb-16 md:pt-16 md:pb-24 items-center'>
      <div className='md:w-[50%] px-4 md:px-6 lg:px-4 '>
        <AnimatedShinyText className="inline-flex items-center justify-center transition ease-out hover:text-neutral-600 hover:duration-200 hover:dark:text-neutral-400">
          <span className='border rounded-full py-1 px-5 flex'>
            <span className="border rounded-lg w-fit px-2 -ml-3 mr-2 flex items-center">
              <span className="w-2 h-2 bg-purple-500 rounded-full shadow-sm shadow-purple-500/50 inline-block mb-0.5 mr-1"></span>
              {category}
            </span>
            4 min read
          </span>
        </AnimatedShinyText>
        <HyperText
          className="text-xl md:text-2xl lg:text-4xl font-bold text-black dark:text-white"
          text={title}
        />
        <p className='text-[#475467] text-base md:text-lg lg:text-xl font-normal my-4'>
          {description}
        </p>
      </div>
      <div className=' w-full md:w-[50%]  mt-8 md:mt-0 md:ml-6 lg:ml-9 overflow-hidden '>
        {/* <img src={`/projectPage/${shortTitle}/${shortTitle}1.png`} alt="Project" className="w-full shadow-md "/> */}
        <img src={image.replace(".","")} alt={image.replace(".","")} className="w-full shadow-md "/> 
      </div>
    </div>
  );
};

export default ProjectHero;
